// See https://getbootstrap.com/docs/5.2/customize/sass/
// See also https://github.com/twbs/bootstrap/blob/cb021439c683d9805e2864c58095b92d405e9b11/scss/bootstrap.scss

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

$link-decoration: none;
$link-hover-decoration: underline;
$primary: #6610f2; // $indigo
$secondary: tint-color($primary, 60%); // $indigo-200
$dark: shade-color($primary, 80%); // $indigo-900

// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

// 6. Optionally include any other parts as needed
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
//@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/nav";
//@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
//@import "../node_modules/bootstrap/scss/accordion";
//@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/alert";
//@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/list-group";
//@import "../node_modules/bootstrap/scss/close";
//@import "../node_modules/bootstrap/scss/toasts";
//@import "../node_modules/bootstrap/scss/modal";
//@import "../node_modules/bootstrap/scss/tooltip";
//@import "../node_modules/bootstrap/scss/popover";
//@import "../node_modules/bootstrap/scss/carousel";
//@import "../node_modules/bootstrap/scss/spinners";
//@import "../node_modules/bootstrap/scss/offcanvas";
//@import "../node_modules/bootstrap/scss/placeholders";
@import "../node_modules/bootstrap/scss/helpers";

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "../node_modules/bootstrap/scss/utilities/api";

// 8. Add additional custom code here

// Required to make things like /events links look not stupid
a:not([href]) {
  color: inherit;
  text-decoration: none;
}

$top-nav-colour: shade-color($primary, 20%); // $indigo-600

.page-header {
  line-height: 1;
  border-bottom: 1px solid $top-nav-colour;
}

.top-nav-active {
  font-weight: $font-weight-bold;
  color: shade-color($primary, 40%); // $indigo-700
}

.top-nav-inactive {
  color: $top-nav-colour;
}

.header-icon {
  fill: $dark;
}
